import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, NgZone, PLATFORM_ID } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { ssrDocument, ssrWindow } from 'ssr-window';

export function _window(): any {
    return ssrWindow;
}
export function _document(): any {
    return ssrDocument;
}

export const WINDOW: InjectionToken<string> = new InjectionToken('WindowToken');

@Injectable()
export abstract class WindowRef {
    public width$: Observable<number>;

    get nativeWindow(): any {
        return null;
    }

    get nativeDocument(): any {
        return null;
    }
}

@Injectable()
export class BrowserWindowRef extends WindowRef {
    private widthSubject = new BehaviorSubject<number>(this.nativeWindow.innerWidth);
    public width$: Observable<number> = this.widthSubject.asObservable();

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private eventManager: EventManager,
        private zone: NgZone
    ) {
        super();

        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                this.eventManager.addEventListener(
                    this.nativeWindow,
                    'resize',
                    this.onResize.bind(this)
                );
            });
        }
    }

    private onResize(event: any) {
        this.widthSubject.next(event.target.innerWidth);
    }

    get nativeWindow(): any {
        return isPlatformBrowser(this.platformId) ? window : _window();
    }

    get nativeDocument(): any {
        return isPlatformBrowser(this.platformId) ? document : _document();
    }
}

export const WINDOW_PROVIDERS = [
    { provide: WindowRef, useClass: BrowserWindowRef },
    { provide: WINDOW, useFactory: _window, deps: [] }
];

